import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { between } from 'polished';

const spinAnimation = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(359deg);
	}
`;

const StyledAlert = styled.div`
	background: ${props => props.theme.colors.warm_grey_100};
	border: solid 1px ${props => props.theme.colors.warm_grey_200};
	padding: 23px;
	text-align: left;
	font-size: ${between('14px', '16px')};
	line-height: ${between('22px', '26px')};

	.icon {
		color: ${props => props.theme.colors.warm_grey_600};
		margin-right: 15px;
		font-size: 20px;
		vertical-align: sub;

		&.rotate {
			animation: ${spinAnimation} 2s infinite linear;
		}
	}

	&.success {
		background: ${props => props.theme.colors.green_100};
		border-color: ${props => props.theme.colors.green_200};

		.icon {
			color: ${props => props.theme.colors.green_400};
		}
	}

	a {
		font-weight: 700;
	}
`;

const Alert = ({ className, children }) => (
	<StyledAlert className={className}>
		{children}
	</StyledAlert>
);

Alert.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Alert;
