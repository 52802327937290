import auth0 from 'auth0-js';
import { navigate } from 'gatsby';

const isBrowser = typeof window !== 'undefined';

export default class Auth {
	auth0 = isBrowser ? new auth0.WebAuth({
		domain: process.env.GATSBY_AUTH0_DOMAIN,
		clientID: process.env.GATSBY_AUTH0_CLIENT_ID,
		redirectUri: `${process.env.GATSBY_SELF_URL}/callback/`,
		audience: `https://${process.env.GATSBY_AUTH0_DOMAIN}/api/v2/`,
		responseType: 'token id_token',
		scope: 'openid profile',
	}) : {};

	constructor() {
		this.login = this.login.bind(this);
		this.logout = this.logout.bind(this);
		this.handleAuthentication = this.handleAuthentication.bind(this);
		this.isAuthenticated = this.isAuthenticated.bind(this);
	}

	login(path) {
		if (!isBrowser) return;
		if (typeof window !== 'undefined' && window && window.localStorage) window.localStorage.setItem('pathToRedirect', path);
		this.auth0.authorize();
	}

	logout() {
		if (typeof window !== 'undefined' && window && window.localStorage) {
			window.localStorage.removeItem('userAccessToken');
			window.localStorage.removeItem('expiresAt');
			window.localStorage.removeItem('userAvatar');
			window.localStorage.removeItem('userName');
		}

		this.auth0.logout();
		navigate('/');
	}

	isAuthenticated() {
		if (typeof window !== 'undefined' && window && window.localStorage && window.localStorage.getItem('expiresAt') && window.localStorage.getItem('userAccessToken')) {
			try {
				const expiresAt = JSON.parse(window.localStorage.getItem('expiresAt'));
				return new Date().getTime() < expiresAt;
			} catch (e) { }
		}

		return false;
	}

	handleAuthentication() {
		if (typeof window !== 'undefined' && window && window.localStorage) {
			this.auth0.parseHash((err, authResult) => {
				if (authResult && authResult.accessToken && authResult.idTokenPayload) {
					window.localStorage.setItem('userAccessToken', authResult.accessToken);
					window.localStorage.setItem('expiresAt', JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime()));
					window.localStorage.setItem('userAvatar', authResult.idTokenPayload.picture);

					this.auth0.client.userInfo(authResult.accessToken, (err, user) => {
						if (err) this.login();
						else if (user.given_name && user.given_name.length > 1) window.localStorage.setItem('userName', user.given_name);
					});
				} else if (err) {
					console.log(err); // @todo - save error
				} else return this.login();

				const pathToRedirect = window.localStorage.getItem('pathToRedirect') || '/profil/';
				window.localStorage.removeItem('pathToRedirect');
				navigate(pathToRedirect);
			});
		}
	}
}
