import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { between } from 'polished';
import { FaLongArrowAltRight, FaInfoCircle, FaExclamationCircle, FaSpinner, FaPencilAlt } from 'react-icons/fa';

import Auth from '../utils/auth.js';
import Page from '../components/page/page';
import HeaderSection from '../components/header-section';
import HeaderFront from '../components/header-front';
import Navigation from '../components/navigation';
import HeaderHeading from '../components/header-heading';
import HeaderDescription from '../components/header-description';
import SEO from '../components/seo';
import Container from '../components/container';
import Thumbnail from '../components/thumbnail';
import Alert from '../components/alert';

const StyledHeaderSection = styled(HeaderSection)`
	min-height: ${between('280px', '470px')};
`;

const PostSummary = styled.div`
	margin-bottom: 70px;

	.info {
		font-size: 12px;
	}

	& + & {
		padding-top: 50px;
		border-top: dashed 1px ${props => props.theme.colors.warm_grey_200};
	}

	.details {
		p {
			line-height: ${between('22px', '26px')};
		}

		@media (min-width: ${props => props.theme.breakpoints.sm}) {
			display: flex;

			.summary {
				padding: ${between('23px', '46px')};
				flex: 1;

				p {
					max-width: 560px;
				}

				.meta {
					margin-top: 50px;

					svg {
						margin-right: 8px;
						fill: ${props => props.theme.colors.warm_grey_400};
					}
				}
			}
		}
	}
`;

const StyledThumbnail = styled(Thumbnail)`
	max-width: 360px;
	margin-left: auto;
	margin-right: auto;

	@media (min-width: ${props => props.theme.breakpoints.sm}) {
		width: 360px;
		margin-left: initial;
		margin-right: initial;
	}
`;

const StyledAlert = styled(Alert)`
	a,
	.link {
		color: ${props => props.theme.colors.golden_500};
		cursor: pointer;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
`;

class ProfilePage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			name: '',
			isLoading: true,
			isError: false,
			posts: [],
			isUserLogged: false,
		};

		this.logIn = this.logIn.bind(this);
		this.handleLogInKeyPress = this.handleLogInKeyPress.bind(this);
	};

	componentDidMount() {
		const auth = new Auth();
		if (!auth.isAuthenticated()) return;

		this.setState({ isUserLogged: true });

		this.getUserPosts();

		if (typeof window !== 'undefined' && window && window.localStorage) {
			const name = window.localStorage.getItem('userName');
			if (name) this.setState({ name });
		}
	}

	logIn() {
		const auth = new Auth();
		auth.login('/profil/');
	}

	handleLogInKeyPress(event) {
		if (event.charCode === 13 || event.charCode === 32) this.logIn();
	}

	getUserPosts() {
		if (typeof window !== 'undefined' && window && window.localStorage && window.localStorage.getItem('userAccessToken')) {
			const accessToken = window.localStorage.getItem('userAccessToken');

			if (!accessToken) {
				this.setState({ isUserLogged: false });
				return;
			}

			fetch('/.netlify/functions/get-posts', { headers: { Authorization: `Bearer ${accessToken}` } })
				.then(res => res.json())
				.then(response => {
					this.setState({ isLoading: false, posts: response });
				})
				.catch(error => {
					this.setState({ isLoading: false, isError: true });
					// @todo - save error
					console.error('[API]', error);
				});
		}
	}

	humanDate(timestamp) {
		const [year, month, day] = timestamp.substring(0, 10).split('-');
		const months = ['stycznia', 'lutego', 'marca', 'kwietnia', 'maja', 'czerwca', 'lipca', 'sierpnia', 'września', 'października', 'listopada', 'grudnia'];
		const monthInt = parseInt(month, 10) - 1;
		return `${day} ${months[monthInt]} ${year}`;
	}

	render() {
		let helloMessage = 'Cześć';
		if (this.state.name && this.state.name.length > 0) helloMessage += `, ${this.state.name}`;
		helloMessage += `!`;

		const planLabels = {
			silver: 'Srebrnego',
			golden: 'Złotego',
			diamond: 'Diamentowego',
		};

		return (
			<Page>
				<SEO title="Twój profil" description="Zarządzaj swoimi ofertami na portalu Weselnicy.pl" />
				<StyledHeaderSection hero="/hero-profile.jpg">
					<HeaderFront>
						<Navigation />
						<Container>
							<HeaderHeading children={helloMessage} />
							{!this.state.isUserLogged &&
								<HeaderDescription>
									<button type="button" onClick={this.logIn}>Zaloguj się <FaLongArrowAltRight /></button>
								</HeaderDescription>
							}
						</Container>
					</HeaderFront>
				</StyledHeaderSection>

				<Container>
					<h2>Moje oferty</h2>

					{this.state.isUserLogged &&
						<>
							{this.state.isLoading &&
								<StyledAlert>
									<FaSpinner className="icon rotate" /> Ładowanie...
								</StyledAlert>
							}

							{!this.state.isLoading &&
								<>
									{this.state.isError &&
										<StyledAlert>
											<FaExclamationCircle className="icon" /> Ups... nie udało się pobrać listy ofert, spróbuj ponownie za chwilę
										</StyledAlert>
									}

									{!this.state.isError &&
										<>
											{this.state.posts.length === 0 &&
												<StyledAlert>
													<FaInfoCircle className="icon" /> Nie posiadasz jeszcze żadnych ofert. Pokaż czym się zajmujesz i zdobądź nowych klientów. <Link to="/dodaj-oferte/">Dodaj ofertę&nbsp;<FaLongArrowAltRight /></Link>
												</StyledAlert>
											}

											{this.state.posts.length > 0 &&
												<>
													{this.state.posts.map(post => (
														<PostSummary key={post.external_uid}>
															<p className="info">Podgląd miniatury:</p>

															<div className="details">
																<StyledThumbnail post={post} />

																<div className="summary">
																	{post.accepted === 0 &&
																		<StyledAlert>
																			<FaInfoCircle className="icon" /> Oferta czeka na zaakceptowanie
																		</StyledAlert>
																	}

																	{post.accepted === 1 &&
																		<>
																			<p>
																				Korzystasz z {post.plan === 'silver' && 'darmowego'} <b>Pakietu {planLabels[post.plan]}</b>.
																			</p>

																			{(post.plan === 'golden' || post.plan === 'diamond') &&
																				<p>
																					{post.paid === 0 &&
																						<>
																							{post.recurring === 0 &&
																								<>
																									Okres testowy kończy się <b>{this.humanDate(post.end_timestamp)}</b>. Utwórz subskrypcję, aby nie stracić dostępu do aktualnych możliwości.
																								</>
																							}

																							{post.recurring === 1 &&
																								<>
																									Okres testowy kończy się <b>{this.humanDate(post.end_timestamp)}</b> i od tego momentu zacznie obowiązywać płatna subskrypcja.
																								</>
																							}
																						</>
																					}
																					{post.paid === 1 &&
																						<>
																							{post.recurring === 0 &&
																								<>
																									Okres rozliczeniowy kończy się <b>{this.humanDate(post.end_timestamp)}</b>. Utwórz subskrypcję, aby nie stracić dostępu do aktualnych możliwości.
																								</>
																							}

																							{post.recurring === 1 &&
																								<>
																									Subskrypcja jest aktywna. Następna płatność nastąpi {this.humanDate(post.end_timestamp)}.
																								</>
																							}
																						</>
																					}
																				</p>
																			}
																		</>
																	}

																	{/* <p className="meta">
																		<Link to={`/edytuj-oferte/?id=${post.external_uid}`}>
																			<FaPencilAlt />Edytuj ofertę
																		</Link>
																	</p> */}
																</div>
															</div>
														</PostSummary>
													))}
												</>
											}
										</>
									}
								</>
							}
						</>
					}

					{!this.state.isUserLogged &&
						<StyledAlert>
							<FaInfoCircle className="icon" />
							<span className="link" role="button" tabIndex="0" onClick={this.logIn} onKeyPress={this.handleLogInKeyPress}>Zaloguj się</span>, aby zobaczyć swoje oferty
						</StyledAlert>
					}
				</Container>
			</Page>
		);
	};
};

export default ProfilePage;
